import { makeObservable, observable } from 'mobx'
import moment from 'moment'

import { ADDRESS_TYPE, PRA_STATUS } from 'definitions'
import { formatDate, formatNumber, praLongDateFormat } from 'utils/formatters'
import BaseStore, { defaults } from 'stores/base'

const fileHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export default class PraStore extends BaseStore {
  Approvers = { ...defaults.Table, data: [] }
  CreateByKey = { ...defaults.CRUD }
  Lookups = { ...defaults.CRUD, loaded: false }
  GlAccounts = { ...defaults.Table, loaded: false }
  JobCodes = { ...defaults.Table, loaded: false }
  ExpenseCodes = { ...defaults.Table, loaded: false }
  Departments = { ...defaults.Table, loaded: false }
  Divisions = { ...defaults.Table, loaded: false }
  Branches = { ...defaults.Table, loaded: false }

  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options

    makeObservable(this, {
      Lookups: observable,
      GlAccounts: observable,
      JobCodes: observable,
      ExpenseCodes: observable,
      Departments: observable,
      Divisions: observable,
      Branches: observable,
      Approvers: observable,
      CreateByKey: observable,
    })
  }

  fetchTable(payload, options) {
    return this.getRequest(
      this.Table,
      () => this.service.fetchTable(payload, options),
      newData => {
        this.Table.data = newData.pras
        this.Table.hasMorePages = newData.hasMorePages
      }
    )
  }

  preparePra(payload) {
    return this.getRequest(
      this.CRUD,
      () => this.service.preparePra(payload, { ignoreGlobalMessage: true }),
      newData => {
        const { purchaseRequestApproval: pra, ...rest } = newData
        const { lines, supplierAttachment, approverAttachment } = pra

        this.CRUD.data = {
          ...pra,
          supplierAttachments: supplierAttachment ? supplierAttachment.existing : [],
          approverAttachments: approverAttachment ? approverAttachment.existing : [],
          lines:
            lines &&
            lines.map(item => {
              const { category, subtotal, deliveryInfo, ...itemRest } = item

              return {
                ...itemRest,
                category,
                subCategory: category.subcategory ? { id: 0, name: category.subcategory } : null,
                price: item.price?.value,
                quantity: item.quantity?.value,
                subTotal: formatNumber(item.subtotal?.value || 0, 2),
                deliveryInfo: deliveryInfo && {
                  ...deliveryInfo,
                  deliveryDate: deliveryInfo?.deliveryDate
                    ? moment(deliveryInfo.deliveryDate, praLongDateFormat).toDate()
                    : null,
                },
              }
            }),
        }
        this.CRUD.settings = { ...rest }
      }
    )
  }

  createByKey(payload) {
    return this.getRequest(
      this.CreateByKey,
      () => this.service.createByKey(payload),
      () => {}
    )
  }

  getApprovers(payload) {
    return this.getRequest(
      this.Approvers,
      () => this.service.getApprovers(payload, { ignoreGlobalMessage: true }),
      newData => {
        this.Approvers.data = newData.approvers
        this.Approvers.creatorNplusOne = newData.creatorNplusOne
      }
    )
  }

  async getApproversLookup(payload) {
    const { data, error } = await this.service.getApprovers(payload, { ignoreGlobalMessage: true })
    return { data, error }
  }

  async fetchLookups(payload) {
    if (!this.Lookups.loaded) {
      return await this.getRequest(
        this.Lookups,
        () => this.service.fetchLookups(payload),
        newData => {
          const { sites, costCenters, ...rest } = newData

          this.Lookups.data = {
            ...rest,
            costCenters: costCenters.map(item => ({
              ...item,
              code: item.costCenterCode,
              name: item.costCenter,
            })),
            sites: sites.map(item => ({
              ...item,
              clientSiteId: item.id,
            })),
          }
          this.Lookups.loaded = true
        }
      )
    }
    return {}
  }

  async fetchGlAccounts(payload) {
    if (!this.GlAccounts.loaded) {
      return await this.getRequest(
        this.GlAccounts,
        () => this.service.fetchGlAccounts(payload),
        newData => {
          this.GlAccounts.data = newData.glAccounts
          this.GlAccounts.loaded = true
        }
      )
    }
    return []
  }

  async fetchJobCodes(payload) {
    if (!this.JobCodes.loaded) {
      return await this.getRequest(
        this.JobCodes,
        () => this.service.fetchJobCodes(payload),
        newData => {
          this.JobCodes.data = newData.jobCodes
          this.JobCodes.loaded = true
        }
      )
    }
    return []
  }

  async fetchExpenseCodes(payload) {
    if (!this.ExpenseCodes.loaded) {
      return await this.getRequest(
        this.ExpenseCodes,
        () => this.service.fetchExpenseCodes(payload),
        newData => {
          this.ExpenseCodes.data = newData.expenseCodes
          this.ExpenseCodes.loaded = true
        }
      )
    }
    return []
  }

  async fetchDepartments(payload) {
    if (!this.Departments.loaded) {
      return await this.getRequest(
        this.Departments,
        () => this.service.fetchDepartments(payload),
        newData => {
          this.Departments.data = newData.departments
          this.Departments.loaded = true
        }
      )
    }
    return []
  }

  async fetchDivisions(payload) {
    if (!this.Divisions.loaded) {
      return await this.getRequest(
        this.Divisions,
        () => this.service.fetchDivisions(payload),
        newData => {
          this.Divisions.data = newData.divisions
          this.Divisions.loaded = true
        }
      )
    }
    return []
  }

  async fetchBranches(payload) {
    if (!this.Branches.loaded) {
      return await this.getRequest(
        this.Branches,
        () => this.service.fetchBranches(payload),
        newData => {
          this.Branches.data = newData.branches
          this.Branches.loaded = true
        }
      )
    }
    return []
  }

  async searchSuppliers(payload) {
    const { data, error } = await this.service.searchSuppliers(payload)
    return { data, error }
  }

  async upload(payload) {
    const { data, error } = await this.service.upload(payload, fileHeaders)
    return { data, error }
  }

  async submitPra({ pra, loggedinEmployeeId, settings, values, isEdit }) {
    const { action, requester, lines, supplierAttachments, approverAttachments, ...rest } = values

    const payload = {
      loggedinEmployeeId,
      action,
      pra: {
        ...rest,
        id: pra.id,
        status: PRA_STATUS.UNSTARTED.id,
        clientId: pra.clientId,
        type: pra.type,
        praType: 0,
        ...(isEdit
          ? {
              currentApproverId: pra.currentApproverId,
            }
          : {}),
        creator: pra.creator,
        requester: { id: requester.id },
        totalAmount: parseFloat(values.totalAmount),
        supportingDocumentsUrl: pra.supportingDocumentsUrl,
        supplierAttachment: supplierAttachments?.added && { added: supplierAttachments.added.map(item => item.fileId) },
        approverAttachment: approverAttachments?.added && { added: approverAttachments.added.map(item => item.fileId) },
        lines: lines.map(line => {
          const { lineNumber, deliveryInfo, category, subCategory, subTotal, ...restLine } = line
          const { multiAddressAttachment } = deliveryInfo
          let multiAddress

          if (deliveryInfo.isMultiAddress && multiAddressAttachment) {
            const [added] = multiAddressAttachment.added || []
            const [attachment] = multiAddressAttachment.attachments || []
            multiAddress = added
              ? {
                  fileId: added.fileId,
                  fileName: added.name,
                }
              : attachment
          }

          let deliveryRequester
          if (deliveryInfo.deliverTo === 'other') {
            deliveryRequester = {
              requesterId: null,
              displayname: deliveryInfo?.otherRequester,
            }
          }
          // View/Edit PRA
          else if (deliveryInfo.requester?.requesterId) {
            deliveryRequester = {
              requesterId: deliveryInfo.requester.requesterId,
              displayname: deliveryInfo.requester.displayname,
            }
          }
          // Create PRA
          else if (deliveryInfo.requester?.key) {
            deliveryRequester = {
              requesterId: deliveryInfo.requester.key,
              displayname: deliveryInfo.requester.value,
            }
          } else {
            deliveryRequester = {
              requesterId: requester.id,
              displayname: requester.name,
            }
          }

          return {
            ...restLine,
            lineNumber: parseInt(lineNumber),
            quantity: { value: parseFloat(line.quantity) },
            price: { value: parseFloat(line.price) },
            subtotal: { value: parseFloat(line.subTotal) },
            costCenter: line.costCenter && {
              id: line.costCenter.id,
              code: line.costCenter.code,
              name: line.costCenter.name,
            },
            deliveryInfo: {
              isMultiAddress: deliveryInfo.isMultiAddress,
              multiAddress,
              deliveryDate: formatDate(deliveryInfo.deliveryDate, praLongDateFormat),
              requester: deliveryRequester,
              ...(deliveryInfo.addressType === ADDRESS_TYPE.CLIENT
                ? {
                    clientSiteId: deliveryInfo?.clientAddress?.clientSiteId ?? deliveryInfo.clientSiteId,
                    deliveryAddress: {},
                  }
                : {
                    clientSiteId: 0,
                    deliveryAddress: {
                      ...deliveryInfo.deliveryAddress,
                      countryCode: deliveryInfo.deliveryAddress.country?.code,
                    },
                  }),
            },
            category: {
              code: category.code,
              name: category.name,
              subCategory: settings.showSubCategories ? subCategory?.name : null,
            },
            ...(settings.enableGLAccount ? { glAccount: line.glAccount } : {}),
            ...(settings.enableJobCode ? { jobCode: line.jobCode } : {}),
            ...(settings.enableExpenseCode ? { expenseCode: line.expenseCode } : {}),
            ...(settings.enableWBSElement ? { wbsElement: line.wbsElement } : {}),
            ...(settings.enableOrderNumber ? { orderNumber: line.orderNumber } : {}),
            ...(settings.enableDepartment ? { department: line.department } : {}),
            ...(settings.enableDivision ? { division: line.division } : {}),
            ...(settings.enableBranch ? { branch: line.branch } : {}),
            ...(settings.enableLicensePlate ? { licensePlate: line.licensePlate } : {}),
          }
        }),
        webshopServiceContract: pra.webshopServiceContract,
      },
    }

    return this.request(this.CRUD, () => this.service.submitPra(payload, { ignoreGlobalMessage: true }))
  }

  async updatePra({
    pra,
    currentApproverEdit,
    setAsArchived = false,
    allowAdministratorOverride,
    settings = {},
    projectCode,
    lines,
    ...rest
  }) {
    const payload = {
      ...rest,
      praId: pra.id,
      clientId: pra.clientId,
      setAsArchived,
      ...(currentApproverEdit ? { currentApproverEdit } : {}),
      ...(allowAdministratorOverride
        ? {
            override: true,
            projectCode,
            lines: lines.map(line => {
              const { lineNumber, category, subCategory } = line

              return {
                lineNumber: parseInt(lineNumber),
                category: {
                  code: category.code,
                  name: category.name,
                  subCategory: settings.showSubCategories ? subCategory?.name : null,
                },
                costCenter: line.costCenter && {
                  id: line.costCenter.id,
                  code: line.costCenter.code,
                  name: line.costCenter.name,
                },
                ...(settings.enableGLAccount ? { glAccount: line.glAccount } : {}),
                ...(settings.enableJobCode ? { jobCode: line.jobCode } : {}),
                ...(settings.enableExpenseCode ? { expenseCode: line.expenseCode } : {}),
                ...(settings.enableWBSElement ? { wbsElement: line.wbsElement } : {}),
                ...(settings.enableOrderNumber ? { orderNumber: line.orderNumber } : {}),
                ...(settings.enableDepartment ? { department: line.department } : {}),
                ...(settings.enableDivision ? { division: line.division } : {}),
                ...(settings.enableBranch ? { branch: line.branch } : {}),
                ...(settings.enableLicensePlate ? { licensePlate: line.licensePlate } : {}),
              }
            }),
          }
        : {}),
    }

    return this.request(this.CRUD, () => this.service.updatePra(payload, { ignoreGlobalMessage: true }))
  }
}
