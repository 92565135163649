import React from 'react'

import { formatCurrency, formatDate, formatNumber } from 'utils/formatters'
import { useStores } from 'stores'
import FileLink from 'common/FileLink'
import ReactTable from 'common/ReactTable'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, pra }) => {
  const { authStore } = useStores()

  const {
    AppSession: { session },
  } = authStore

  const columns = React.useMemo(
    () => [
      {
        headerClassName: 'text-muted col-auto',
        className: 'text-muted col-auto',
        Header: '#',
        Cell: ({ index }) => index + 1,
      },
      {
        Header: resource.ProductDescription,
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        Cell: ({ value }) => <div className="word-wrap-break-word">{value}</div>,
      },
      {
        Header: resource.Quantity,
        accessor: 'quantity',
        headerClassName: 'col-2',
        className: 'col-2',
        Cell: ({ value }) => (value ? formatNumber(value) : '-'),
      },
      {
        Header: resource.Price,
        accessor: 'price',
        headerClassName: 'col-2',
        className: 'col-2',
        footerClassName: 'text-end',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
        Footer: resource.TotalVat,
      },
      {
        Header: resource.Subtotal,
        accessor: 'subtotal',
        headerClassName: 'col-2',
        className: 'col-2',
        footerClassName: 'text-end',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
        Footer: () => `${pra.currencyCode === 'EUR' ? '€ ' : ''} ${pra.totalPrice}`,
      },
      {
        Header: resource.Currency,
        accessor: 'currencyCode',
        headerClassName: 'col-1',
        className: 'col-1',
      },
      {
        fullRow: true,
        Cell: ({ row }) => (
          <div className="p-lg-1 p-0">
            <ReadOnlyField label={resource.Category} value={row.category} formGroup horizontal />
            {session.shouldSelectSubcategory && (
              <ReadOnlyField label={resource.SubCategory} value={row.subcategory} formGroup horizontal />
            )}
            {session.enableGLAccount && (
              <ReadOnlyField label={resource.GLAccount} value={row.glAccount} formGroup horizontal />
            )}
            {!session.disableCostCenter && (
              <ReadOnlyField
                label={resource.CostCenter}
                value={row.costCenter ? `${row.costCenter} ${row.costCenterCode}` : ''}
                formGroup
                horizontal
              />
            )}
            {session.enableJobCode && (
              <ReadOnlyField label={resource.JobCode} value={row.jobCode} formGroup horizontal />
            )}
            {session.enableExpenseCode && (
              <ReadOnlyField label={resource.ExpenseCode} value={row.expenseCode} formGroup horizontal />
            )}
            {session.enableWBSElement && (
              <ReadOnlyField label={resource.WBSElement} value={row.wbsElement} formGroup horizontal />
            )}
            {session.enableOrderNumber && (
              <ReadOnlyField label={resource.OrderNumber} value={row.orderNumber} formGroup horizontal />
            )}
            {session.enableDepartment && (
              <ReadOnlyField label={resource.Department} value={row.department} formGroup horizontal />
            )}
            {session.enableDivision && (
              <ReadOnlyField label={resource.Division} value={row.division} formGroup horizontal />
            )}
            {session.enableBranch && <ReadOnlyField label={resource.Branch} value={row.branch} formGroup horizontal />}
            {session.enableLicensePlate && (
              <ReadOnlyField label={resource.LicensePlate} value={row.licensePlate} formGroup horizontal />
            )}
            <ReadOnlyField
              label={resource.ExtendedDescription}
              value={row.extendedDescription}
              formGroup
              horizontal
              className="word-wrap-break-word"
            />
            <ReadOnlyField
              label={resource.RemarksForSuppliers}
              value={row.remarks}
              formGroup
              horizontal
              className="word-wrap-break-word"
            />
            <ReadOnlyField
              label={resource.PreferredDate}
              value={formatDate(row.preferredDate, 'dddd, DD MMMM YYYY')}
              formGroup
              horizontal
            />
            <ReadOnlyField
              label={resource.DeliveryAddress}
              value={
                row.multiAddressFileId ? (
                  <div className="fw-bold">
                    <div>{resource.MultipleDeliveryAddress}</div>
                    <div>
                      <FileLink
                        data={{
                          fileId: row.multiAddressFileId,
                          fileName: row.multiAddressFileName,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  row.deliveryAddress
                )
              }
              formGroup
              horizontal
            />
          </div>
        ),
      },
    ],

    []
  )

  return (
    <div className="position-relative">
      <ReactTable columns={columns} data={pra.lineItems} responsive showFooter />
    </div>
  )
}
