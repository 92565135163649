import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { PRA_STATUS, ROUTES } from 'definitions'
import { replaceUrl } from 'utils'
import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import Loader from 'common/Loader'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

import GoodsReceivedModal from '../GoodsReceivedModal'
import PraActionModal from './PraActionModal'
import PraFormApprovalFlow from './PraFormApprovalFlow'
import PraFormFooter from './PraFormFooter'
import PraFormGeneralInfo from './PraFormGeneralInfo'
import PraFormHeading from './PraFormHeading'
import PraFormOtherInfo from './PraFormOtherInfo'
import PraFormProducts from './PraFormProducts'

export default observer(({ isEdit, isCopy, options = {}, onClose, onReloadPraList, onRelaunchedPra, onReloadPra }) => {
  const { authStore, praStore, pageResourceStore } = useStores()
  const [pageLoading, setPageLoading] = React.useState()
  const [praActionOptions, setPraActionOptions] = React.useState({})
  const [isOpenGoodsReceivedModal, setIsOpenGoodsReceivedModal] = React.useState()
  const methods = useForm({
    shouldFocusError: false,
  })
  const debouncedFetchApprovers = useDebounce(() => fetchApprovers())

  const { getValues, handleSubmit } = methods
  const {
    AppSession: { session },
  } = authStore
  const {
    CRUD: { data = {}, settings = {}, submitting, error: crudError, loading: crudLoading },
    Approvers: { error: approversError },
    CreateByKey: { error: createByKeyError },
  } = praStore
  const {
    PageResource: { pra: resource = {}, common: commonResource },
  } = pageResourceStore

  const loading = crudLoading || pageLoading
  const error = crudError || approversError || createByKeyError
  const showPra = !loading && (isEdit ? !!data.id : !!data)

  const loggedinEmployeeId = React.useMemo(() => authStore.getLoginEmployeeId(), [authStore])

  const isCreatorOrAdmin = React.useMemo(
    () => (!!data && !!data.creator && data.creator.id === loggedinEmployeeId) || session.hasAdministratorRole,
    [data, loggedinEmployeeId, session]
  )

  const isReadOnly = React.useMemo(() => {
    return isEdit && !!data.id && (!isCreatorOrAdmin || data.status !== PRA_STATUS.UNSTARTED.id)
  }, [isEdit, data, isCreatorOrAdmin])

  React.useEffect(() => {
    if (!isReadOnly || settings.allowAdministratorOverride) {
      // Get all PRA lookups
      // This is only a temporary solution until we optimize all lookups in BE
      const fetchLookups = async () => {
        if (settings.id) {
          setPageLoading(true)
          await Promise.allSettled([
            praStore.fetchLookups({ clientId: session.clientId }),
            settings.enableGLAccount && praStore.fetchGlAccounts({ clientId: session.clientId }),
            settings.enableJobCode && praStore.fetchJobCodes({ clientId: session.clientId }),
            settings.enableExpenseCode && praStore.fetchExpenseCodes({ clientId: session.clientId }),
            settings.enableDepartment && praStore.fetchDepartments({ clientId: session.clientId }),
            settings.enableDivision && praStore.fetchDivisions({ clientId: session.clientId }),
            settings.enableBranch && praStore.fetchBranches({ clientId: session.clientId }),
          ])
          setPageLoading(false)
        }
      }
      fetchLookups()
    }
  }, [praStore, settings, session.clientId, isReadOnly])

  React.useEffect(() => {
    if (options?.showGoodsReceived) {
      setIsOpenGoodsReceivedModal(true)
    }
  }, [options])

  React.useEffect(() => {
    // Clear PRA data upon close
    return () => praStore.clearCrudData()
  }, [praStore])

  React.useEffect(() => {
    data?.encryptedKey && replaceUrl(`${ROUTES.PRA}?pr=${data.encryptedKey}`)
    return () => replaceUrl(ROUTES.PRA)
  }, [data])

  const handleOnSubmit = async values => {
    let result

    switch (values.action) {
      case 'archive':
        result = await praStore.updatePra({
          pra: data,
          loggedinEmployeeId,
          nextState: data.status === PRA_STATUS.REJECTED.id ? PRA_STATUS.REJECTED.id : PRA_STATUS.ABORTED.id,
          setAsArchived: true,
        })
        break
      case 'approve':
      case 'reject':
      case 'reassign':
      case 'question':
      case 'answer': {
        const { currentApproverEdit, question, answers } = values
        result = await praStore.updatePra({
          pra: data,
          loggedinEmployeeId,
          nextState: values.nextState,
          currentApproverEdit: {
            lineIndex: currentApproverEdit.lineIndex,
            status: currentApproverEdit.status,
            comment: currentApproverEdit.comment,
          },
          reassignTo: values.reassignTo,
          ...(question ? { question } : {}),
          ...(answers ? { answers } : {}),
          ...(values.action === 'approve' && settings.allowAdministratorOverride
            ? {
                allowAdministratorOverride: true,
                settings,
                projectCode: values.projectCode,
                lines: values.lines,
              }
            : {}),
          isAdministratorProxy: values.isAdministratorProxy,
        })
        break
      }
      default:
        result = await praStore.submitPra({
          pra: data,
          loggedinEmployeeId,
          settings,
          values,
          isEdit,
          isCopy,
        })
        break
    }

    result && reloadPraList()
  }

  const reloadPraList = () => {
    onClose()
    onReloadPraList()
  }

  const fetchApprovers = () => {
    const session = authStore.AppSession.session
    const data = praStore.CRUD.data
    const loggedinEmployeeId = authStore.getLoginEmployeeId()
    const { requester, lines } = getValues()

    praStore.getApprovers({
      loggedinEmployeeId: authStore.getLoginEmployeeId(),
      clientId: session.clientId,
      creatorId: data.creator?.id || loggedinEmployeeId,
      currentApproverId: data.currentApproverId?.id,
      type: 0,
      praId: data.id ? data.id : null,
      praLines: lines
        ? lines.map(line => {
            const { costCenter, category, subCategory } = line
            return {
              quantity: { value: parseFloat(line.quantity || 0) },
              price: { value: parseFloat(line.price || 0) },
              costCenter: costCenter && {
                id: costCenter.id,
                code: costCenter.costCenterCode,
                name: costCenter.costCenter,
              },
              category: category && {
                code: category.code,
                name: category.name,
                subCategory: settings.showSubCategories ? subCategory?.name : null,
              },
            }
          })
        : [],
      requestorId: requester?.id || loggedinEmployeeId,
    })
  }

  const getTitle = () => {
    if (isEdit) {
      switch (data.status) {
        case PRA_STATUS.UNSTARTED.id:
          return resource.EditPurchaseRequest
        case PRA_STATUS.PENDING.id:
          return resource.ViewPurchaseRequest
        case PRA_STATUS.APPROVED.id:
          return resource.ViewPurchaseOrder
        default:
          return resource.PurchaseRequest
      }
    } else {
      return resource.CreatePurchaseRequest
    }
  }

  const handlePraAction = action => setPraActionOptions({ isOpen: !!action, action })

  const toggleGoodsReceived = () => setIsOpenGoodsReceivedModal(prev => !prev)

  const handleGoodsReceivedSubmit = () => onReloadPra(data)

  return (
    <React.Fragment>
      <Modal
        id="pra-detail"
        isOpen
        useForm={methods}
        title={getTitle()}
        size="xl"
        footer={
          showPra && (
            <PraFormFooter
              praStore={praStore}
              resource={resource}
              disabled={loading}
              isCreatorOrAdmin={isCreatorOrAdmin}
              onClose={onClose}
              onPraAction={handlePraAction}
              onSubmit={handleOnSubmit}
            />
          )
        }
        disableClose={submitting}
        toggle={onClose}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <MessageList messages={error && [error]} />
        <Loader show={loading} className="justify-content-center" />
        {showPra && (
          <React.Fragment>
            {isEdit && <PraFormHeading data={data} resource={resource} />}
            <PraFormGeneralInfo
              praStore={praStore}
              loggedinEmployeeId={loggedinEmployeeId}
              clientId={session.clientId}
              resource={resource}
              commonResource={commonResource}
              isReadOnly={isReadOnly}
              fetchApprovers={debouncedFetchApprovers}
            />
            <PraFormProducts
              isEdit={isEdit}
              isCopy={isCopy}
              data={data}
              settings={settings}
              resource={resource}
              disabled={submitting}
              isReadOnly={isReadOnly}
              fetchApprovers={debouncedFetchApprovers}
              onShowGoodsReceived={toggleGoodsReceived}
            />
            <PraFormOtherInfo data={data} resource={resource} disabled={submitting} isReadOnly={isReadOnly} />
            <PraFormApprovalFlow
              praStore={praStore}
              session={session}
              isEdit={isEdit}
              isCopy={isCopy}
              data={data}
              settings={settings}
              loggedinEmployeeId={loggedinEmployeeId}
              resource={resource}
              disabled={submitting}
              isReadOnly={isReadOnly}
              fetchApprovers={debouncedFetchApprovers}
            />
          </React.Fragment>
        )}
      </Modal>
      {praActionOptions.isOpen && (
        <PraActionModal
          praStore={praStore}
          data={data}
          action={praActionOptions.action}
          loggedinEmployeeId={loggedinEmployeeId}
          resource={resource}
          onClose={() => handlePraAction()}
          onSubmit={reloadPraList}
          onRelaunch={() => onRelaunchedPra(data)}
        />
      )}
      {showPra && isOpenGoodsReceivedModal && (
        <GoodsReceivedModal praId={data.id} onSubmit={handleGoodsReceivedSubmit} onClose={toggleGoodsReceived} />
      )}
    </React.Fragment>
  )
})
