import React from 'react'

import { Collapse } from 'reactstrap'
import { FlexTableCell } from 'common/FlexTable'
import { formatString } from 'utils/formatters'

import { useFormContext } from 'react-hook-form'
import { useStores } from 'stores'

import { validateMaxLength, validateSpecialCharacters } from 'utils/validators'
import Checkbox from 'common/Forms/Checkbox'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

import PraFormProductItemCategory from './PraFormProductItemCategory'
import PraFormProductItemDeliveryInfo from './PraFormProductItemDeliveryInfo'

export default ({
  praStore,
  resource,
  index,
  isOpen,
  lineItem,
  isReadOnly,
  fetchApprovers,
  onChange,
  clientId,
  isEdit,
}) => {
  const { setValue } = useFormContext()
  const { pageResourceStore } = useStores()

  const [isAsset, setIsAsset] = React.useState(lineItem.isAsset)
  const [selectedCostcenter, setSelectedCostcenter] = React.useState(null)

  const {
    CRUD: { settings = {}, submitting },
    Lookups: { data: { categories = [], costCenters = [] } = {} },
    GlAccounts: { data: glAccounts },
    JobCodes: { data: jobCodes },
    ExpenseCodes: { data: expenseCodes },
    Departments: { data: departments },
    Divisions: { data: divisions },
    Branches: { data: branches },
  } = praStore

  const {
    PageResource: { common: commonResource = {} },
  } = pageResourceStore

  const defaultCostCenter = React.useMemo(() => {
    if (lineItem.costCenter) {
      return lineItem.costCenter
    } else {
      return costCenters.find(item => item.id === settings.defaultCostCenterId)
    }
  }, [lineItem, settings.defaultCostCenterId, costCenters])

  const assetCostCenter = React.useMemo(() => {
    const acc = costCenters.find(item => item.id === settings.assetCostCenterId)
    if(acc) return acc;
    else return defaultCostCenter
  }, [settings.assetCostCenterId, costCenters, defaultCostCenter])


  const handleCostCenterOnChange = (newValue) => {
    setSelectedCostcenter(newValue)
    fetchApprovers()
    onChange(index, 'costCenter', newValue)
  }

  const handleIsAssetOnChange = (e) => {
    setIsAsset(e.target.checked)
    if(e.target.checked){
      setValue(`lines.${index}.costCenter`, assetCostCenter)
      handleCostCenterOnChange(assetCostCenter)
    }
  }

  return (
    <div className="col-12">
      <FlexTableCell className="flex-wrap pt-lg-3 py-0">
        <PraFormProductItemCategory
          index={index}
          resource={resource}
          settings={settings}
          categories={categories}
          lineItem={lineItem}
          defaultSubCategory={lineItem.subCategory}
          disabled={submitting}
          isReadOnly={isReadOnly}
          fetchApprovers={fetchApprovers}
          onChange={onChange}
        />
        {settings.enableGLAccount && (
          <ReactSelect
            name={`lines.${index}.glAccount`}
            rules={{ required: resource.Required }}
            label={`${resource.GLAccount}${isReadOnly ? '' : ' *'} `}
            defaultValue={lineItem.glAccount}
            placeholder={resource.SelectGLAccount}
            options={glAccounts}
            optionValueKey="id"
            optionLabelKey="glAccount"
            formGroup
            horizontal
            isClearable
            isDisabled={submitting}
            readOnlyField={!settings.allowAdministratorOverride && isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            onChange={selected => onChange(index, 'glAccount', selected)}
          />
        )}
        {!settings.disableCostCenter && (
          <ReactSelect
            name={`lines.${index}.costCenter`}
            rules={{ required: resource.Required }}
            label={`${resource.CostCenter}${isReadOnly ? '' : ' *'} `}
            defaultValue={defaultCostCenter}
            value = {selectedCostcenter ?? defaultCostCenter}
            placeholder={resource.SelectCostCenter}
            options={costCenters}
            optionValueKey="id"
            optionLabelKey="name"
            formGroup
            horizontal
            isClearable
            isDisabled={submitting}
            readOnlyField={!settings.allowAdministratorOverride && isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            onChange={handleCostCenterOnChange}
          />
        )}
        {settings.enableJobCode && (
          <ReactSelect
            name={`lines.${index}.jobCode`}
            rules={{ required: resource.Required }}
            label={`${resource.JobCode}${isReadOnly ? '' : ' *'} `}
            defaultValue={lineItem.jobCode}
            placeholder={resource.SelectJobCode}
            options={jobCodes}
            optionValueKey="id"
            optionLabelKey="name"
            formGroup
            horizontal
            isClearable
            isDisabled={submitting}
            readOnlyField={!settings.allowAdministratorOverride && isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            onChange={selected => onChange(index, 'jobCode', selected)}
          />
        )}
        {settings.enableExpenseCode && (
          <ReactSelect
            name={`lines.${index}.expenseCode`}
            rules={{ required: resource.Required }}
            label={`${resource.ExpenseCode}${isReadOnly ? '' : ' *'} `}
            defaultValue={lineItem.expenseCode}
            placeholder={resource.SelectExpenseCode}
            options={expenseCodes}
            optionValueKey="id"
            optionLabelKey="name"
            formGroup
            horizontal
            isClearable
            isDisabled={submitting}
            readOnlyField={!settings.allowAdministratorOverride && isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            onChange={selected => onChange(index, 'expenseCode', selected)}
          />
        )}
        {settings.enableWBSElement && (
          <Input
            name={`lines.${index}.wbsElement`}
            rules={
                settings.isWBSElementRequired && {
                required: resource.Required,
                validate: {
                  specialCharacters: value => {
                    return (
                      settings.allowSpecialCharacters ||
                      validateSpecialCharacters(value, commonResource.SpecialCharacter)
                    )
                  },
                },
              }
            }
            label={`${resource.WBSElement}${isReadOnly || !settings.isWBSElementRequired ? '' : ' *'} `}
            defaultValue={lineItem.wbsElement}
            formGroup
            horizontal
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            disabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            className="word-break-break-word white-space-normal"
            inputColClassName="px-lg-3 px-0"
          />
        )}
        {settings.enableAsset && (
          <Checkbox
            name={`lines.${index}.isAsset`}
            label=""
            formGroup
            horizontal
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            disabled={submitting || isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="pt-3"
            formLabel={resource.Asset}
            hideLabel={false}
            check={false}
            onChange={handleIsAssetOnChange}
          />
        )}
        {isAsset && (
          <Input
            name={`lines.${index}.investmentNumber`}
            rules={{
                required: resource.Required,
                validate: {
                  maxLength: value => {
                    return validateMaxLength(
                      value,
                      10,
                      formatString(commonResource.MaxLength, 10)
                    )
                  },
                },
              }
            }
            label={`${resource.InvestmentNumber}${isReadOnly ? '' : ' *'} `}
            defaultValue={lineItem.investmentNumber}
            formGroup
            horizontal
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            disabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            className="word-break-break-word white-space-normal"
            inputColClassName="px-lg-3 px-0"
          />
        )}
        {settings.enableOrderNumber && (
          <Input
            name={`lines.${index}.orderNumber`}
            rules={
              settings.isOrderNumberRequired && {
                required: resource.Required,
                validate: {
                  specialCharacters: value => {
                    return (
                      settings.allowSpecialCharacters ||
                      validateSpecialCharacters(value, commonResource.SpecialCharacter)
                    )
                  },
                },
              }
            }
            label={`${resource.OrderNumber}${isReadOnly || !settings.isOrderNumberRequired ? '' : ' *'} `}
            defaultValue={lineItem.orderNumber}
            formGroup
            horizontal
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            disabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            className="word-break-break-word white-space-normal"
            inputColClassName="px-lg-3 px-0"
          />
        )}
        {settings.enableDepartment && (
          <ReactSelect
            name={`lines.${index}.department`}
            rules={
              settings.isDepartmentRequired && {
                required: resource.Required,
              }
            }
            label={`${resource.Department}${isReadOnly || !settings.isDepartmentRequired ? '' : ' *'} `}
            defaultValue={lineItem.department}
            placeholder={resource.SelectDepartment}
            options={departments}
            optionValueKey="id"
            optionLabelKey="name"
            formGroup
            horizontal
            isClearable
            isDisabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            onChange={selected => onChange(index, 'department', selected)}
          />
        )}
        {settings.enableDivision && (
          <ReactSelect
            name={`lines.${index}.division`}
            rules={
              settings.isDivisionRequired && {
                required: resource.Required,
              }
            }
            label={`${resource.Division}${isReadOnly || !settings.isDivisionRequired ? '' : ' *'} `}
            defaultValue={lineItem.division}
            placeholder={resource.SelectDivision}
            options={divisions}
            optionValueKey="id"
            optionLabelKey="name"
            formGroup
            horizontal
            isClearable
            isDisabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            onChange={selected => onChange(index, 'division', selected)}
          />
        )}
        {settings.enableBranch && (
          <ReactSelect
            name={`lines.${index}.branch`}
            rules={
              settings.isBranchRequired && {
                required: resource.Required,
              }
            }
            label={`${resource.Branch}${isReadOnly || !settings.isBranchRequired ? '' : ' *'} `}
            defaultValue={lineItem.branch}
            placeholder={resource.SelectBranch}
            options={branches}
            optionValueKey="id"
            optionLabelKey="name"
            formGroup
            horizontal
            isClearable
            isDisabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            onChange={selected => onChange(index, 'branch', selected)}
          />
        )}
        {settings.enableLicensePlate && (
          <Input
            name={`lines.${index}.licensePlate`}
            rules={
              settings.isLicensePlateRequired && {
                required: resource.Required,
                validate: {
                  specialCharacters: value => {
                    return (
                      settings.allowSpecialCharacters ||
                      validateSpecialCharacters(value, commonResource.SpecialCharacter)
                    )
                  },
                },
              }
            }
            label={`${resource.LicensePlate}${isReadOnly || !settings.isLicensePlateRequired ? '' : ' *'} `}
            defaultValue={lineItem.licensePlate}
            formGroup
            horizontal
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            disabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            className="word-break-break-word white-space-normal"
            inputColClassName="px-lg-3 px-0"
          />
        )}
      </FlexTableCell>
      <Collapse isOpen={isOpen} className="collapse-section">
        <FlexTableCell className="flex-wrap pb-lg-3 py-0">
          <Input
            name={`lines.${index}.extendedDescription`}
            label={resource.ExtendedDescription}
            defaultValue={lineItem.extendedDescription}
            formGroup
            horizontal
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            disabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0 mt-6"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            className="word-break-break-word white-space-normal"
            inputColClassName="px-lg-3 px-0"
          />
          <Input
            name={`lines.${index}.remarks`}
            label={resource.RemarksForSuppliers}
            defaultValue={lineItem.remarks}
            formGroup
            horizontal
            labelCol={{ lg: 3, xl: 5 }}
            inputCol={{ lg: 7, xl: 5 }}
            disabled={submitting}
            readOnlyField={isReadOnly}
            formClassName="col-12 mx-0"
            labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
            inputColClassName="px-lg-3 px-0"
          />
          <PraFormProductItemDeliveryInfo
            {...{ praStore, resource, index, lineItem, isReadOnly, clientId, isEdit }}
            onChange={(key, value) => onChange(index, key, value)}
          />
        </FlexTableCell>
      </Collapse>
    </div>
  )
}
